<template>
  <UiPopup
    :model-value="modelValue"
    :title="image ? 'Crop photo' : 'Add photo'"
    :primary-button-text="error ? 'Try a different one' : 'Save'"
    secondary-button-text="Cancel"
    size="small"
    @update:model-value="emits('update:modelValue', false)"
    @confirm="submit"
  >
    <div class="mx-auto flex h-[365px] w-full items-center justify-center rounded-[4px] bg-black-05">
      <div>
        <input ref="fileInput" type="file" class="hidden" accept="image/*" @change="handleFileChange" />
        <cropper
          v-if="image"
          class="h-[365px] w-[365px]"
          :src="image"
          :stencil-props="{
            aspectRatio: 1,
          }"
          @change="change"
        ></cropper>
        <UiButtonGhost v-else-if="!error" id="upload_photo" @click="fileInput?.click()">Upload photo</UiButtonGhost>
        <div v-else class="mx-auto w-8/12">
          {{ error }}
        </div>
      </div>
    </div>
  </UiPopup>
</template>

<script setup lang="ts">
import { Cropper } from 'vue-advanced-cropper'
import 'vue-advanced-cropper/dist/style.css'

const emits = defineEmits(['update:modelValue', 'input'])

type Props = {
  modelValue: boolean
}

withDefaults(defineProps<Props>(), {
  modelValue: false,
})

const fileInput = ref(null)
const image = ref()
const croppedImage = ref()
const error = ref('')

const handleFileChange = () => {
  const file = fileInput.value?.files[0]
  if (!file) return

  const fileSize = file.size / 1024 / 1024 // MB

  if (fileSize > 1) {
    error.value = "That's a very nice photo, but it's in wrong size. Try one that's smaller than 1Mb."
  } else {
    const reader = new FileReader()

    reader.onload = (e: Event) => {
      // Initiate the JavaScript Image object.
      const newImage = new Image()
      // Set the Base64 string return from FileReader as source.
      newImage.src = (e.target as EventTarget).result
      // Validate the File Height and Width.
      newImage.onload = function () {
        const height = this.height
        const width = this.width
        if (height < 750 || width < 750) {
          error.value = "That's a very nice photo, but it's in wrong resolution. Try one with at least 750x750px"
          return false
        } else {
          image.value = newImage.src

          error.value = ''
          return true
        }
      }
    }
    reader.readAsDataURL(file)
  }
}

const change = ({ canvas }) => {
  croppedImage.value = canvas
}

const submit = () => {
  if (error.value) fileInput.value?.click()
  else {
    emits('input', croppedImage.value.toDataURL('image/jpeg'))
    emits('update:modelValue', false)
  }
}
</script>

<style scoped></style>
